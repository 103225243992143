import classNames from "classnames"
import React from "react"

import { Color } from "../../../constants/V2/color"
import GatsbyStoryblokImage from "../../elements/V2/GatsbyStoryblokImage"
import Typography from "../../elements/V2/Typography"

import { getBackgroundColorClass } from "@utils/V2/color"

type Props = {
  image: string
  description: string
  name: string
  textColor: Color
  backgroundColor: "transparent" | Color.Charcoal | Color.White
}

const TestimonialCard = ({
  image,
  description,
  name,
  textColor,
  backgroundColor,
}: Props) => {
  const backgoundColorClass =
    backgroundColor == "transparent"
      ? "bg-gradient-to-bl from-white/5 to-white/[0.01] border-2 border-white/10"
      : getBackgroundColorClass(backgroundColor)

  return (
    <div
      className={classNames(
        "col-span-full flex flex-col gap-20 rounded-20 p-32 text-left shadow-[0_20px_20px_0px_rgba(0,0,0,0.1)] sm:col-span-2 lg:col-span-4",
        backgoundColorClass
      )}
    >
      <div>
        <GatsbyStoryblokImage
          image={image}
          alt={name}
          height={42}
          aspectRatio="auto"
        />
      </div>
      <div className="flex h-full flex-col justify-between gap-20">
        <Typography
          text={description}
          size="body-md"
          weight="book"
          font="grotesk"
          color={textColor}
        />
        <Typography
          text={name}
          size="body-md"
          font="grotesk"
          weight="book"
          color={textColor}
          className="opacity-50"
        />
      </div>
    </div>
  )
}

export default TestimonialCard
